import axios from 'axios'

const state = {
  merchants: [],
  merchantsAll: [],
  search: '',
  selected: null,
}

const mutations = {
  SET_MERCHANTS: (state, merchants) => state.merchants = merchants,
  SET_MERCHANTS_ALL: (state, merchants) => state.merchantsAll = merchants,
  SELECT_MERCHANT: (state, merchant) => state.selected = merchant,
  SET_SEARCH: (state, search) => state.search = search,
}

const actions = {
  fetchMerchants({ commit, rootState }) {
    axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/merchants/list`, { valueToSearch: rootState.merchants.search })
      .then((r) => {
        commit('SET_MERCHANTS', r.data)
        if (rootState.merchants.search === '') {
          commit('SET_MERCHANTS_ALL', r.data)
        }

        rootState.merchants.selected = null
      })
  },
  downloadProfile({ rootState }) {
    const query = { merchantId: rootState.merchants.selected.id };
    axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/merchants/download/profile`, { responseType: 'blob', params: query })
      .then((r) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(r.data)
        link.download = 'анкета_' + rootState.merchants.selected.name.replace(" ", "_") + '.doc'
        link.click()
      })
  },
  downloadTransportList({ rootState }) {
    const query = { merchantId: rootState.merchants.selected.id };
    axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/merchants/download/transportList`, { responseType: 'blob', params: query })
      .then((r) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(r.data)
        link.download = 'транспортный_лист_' + rootState.merchants.selected.name.replace(" ", "_") + '.doc'
        link.click()
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}